import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import DataS from "../../json/DataS";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import "./Hero.css";
// import required modules
import { Autoplay, EffectFade } from "swiper/modules";

export default function App() {
  return (
    <>
      <div className="ml-0 md:ml-0 mr-0 md:mr-0 mb-10">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          effect={"fade"}
          autoplay={{
            delay: 2500, // Match the progress bar animation duration
            disableOnInteraction: true,
          }}
          modules={[Autoplay, EffectFade]}
          className="mySwiper"
        >
          {DataS.map((item, index) => (
            <SwiperSlide key={index}>
              <img src={item.image} alt={item.title} />
              <div className="absolute abstract-shapeS bottom-1 md:bottom-2 left-0 md:left-2">
                <p className="text-white  text-base md:text-xl  font-bold mt-2 ml-4 md:ml-6">
                {item.title}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
