import BA1 from "./../../media/Beschichtungen/img1.jpg"

const DataB =[
  {
    category: "Beschichtungen",
    image: BA1,
    title: "Beschichtungen",
  },

]

export default DataB;