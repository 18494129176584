//Bausplenger
import img1 from "./../media/carousel/img1.jpg";
import img2 from "./../media/carousel/img2.jpg";
import img3 from "./../media/carousel/img3.jpg";



const DataS = [
  {
    category: "fluessigkunststoff",
    image: img1,
    title: "Fluessigkunststoff",
  },
  {
    category: "kunstharzbelägen",
    image: img2,
    title: "Kunstharzbelägen",
  },
  {
    category: "injektionen",
    image: img3,
    title: "Injektionen",
  },
];
export default DataS;
