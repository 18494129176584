//BLusplenger
import BL1 from "./../../media/Kunstharzbelaegen/img1.jpg"


const DataBL = [
  {
    category: "Kunstharzbelaegen",
    image: BL1,
    title: "Kunstharzbelaegen",
  },

];
export default DataBL;
