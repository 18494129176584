import FL1 from "./../../media/Fluessigkunststoff/img1.jpg"

const DataF =[
  // {
  //     category: "Flachdach",
  //     image: FL1,
  //     title: "Card 1",
  //     // additional_images: [
  //     //   B2,
  //     //   B3,
  //     //   B4,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B10,
  //     //   B2,
  //     //   B3
  //     // ]
    
  // },
  {
    category: "Fluessigkunststoff",
    image: FL1,
    title: "Fluessigkunststoff",
  },
  


]

export default DataF;