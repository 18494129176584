import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import BrowserRouter
import Home from "./components/home/Home";
import Baus from "./components/Beschichtungen/Beschichtungen";
import Flachdach from "./components/Fluessigkunststoff/Fluessigkunststoff";
import Blitzschutze from "./components/Kunstharzbelaegen/Kunstharzbelaegen"
import Injektion from "./components/Injektionen/Injektionen";
function App() {
  return (
    <BrowserRouter>
      {/* Wrap your Routes with BrowserRouter */}
      <div className=" h-full w-full overflow-x-hidden ">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/beschichtungen" element={<Baus />} />
          <Route path="/kunstharzbelaegen" element={<Blitzschutze />} />
          <Route path="/fluessigkunststoff" element={<Flachdach />} />
          <Route path="/injektionen" element={<Injektion />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
