import { useState, useEffect } from "react";
import Modal from "react-modal";
import "./ProjectsBa.css";
import DataB from "../../json/Beschichtungen/DataB";
import AOS from "aos";
import "aos/dist/aos.css";
function Projects() {
  const images = require.context('../../media/Beschichtungen', true);
const imageList = images.keys().map(image => images(image));
console.log(imageList);
  useEffect(() => {
    AOS.init();
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 6;

 
  const openImage = (image) => {
    setSelectedImage(image);
  };
  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const displayedCards = DataB.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages = Math.ceil(DataB.length / cardsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      const projekteElement = document.getElementById("projekte");
      if (projekteElement) {
        projekteElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      const projekteElement = document.getElementById("projekte");
      if (projekteElement) {
        projekteElement.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div id="projekte" className="md:mt-10">
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className="text-3xl text-palette text-center"
      >
        Projekte
      </h1>

      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="sm:grid grid-cols-3"
      >
        <div></div>
        <div className="text-center col-span-1 border-b-2 border-palette mt-4 ml-8 md:ml-24 mr-8 md:mr-24 mb-10 "></div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="md:mb-10 cursor-pointer "
      >
        <div className="flex justify-center">
          <div className=" md:grid grid-cols-3 md:gap-4">
            {imageList.map((card, index) => (
              <div
                className="p-4 hover:scale-105"
                key={index}
                onClick={() => openImage(card)}
              >
                <div className="relative card con">
                  <img
                    alt="gallery"
                    className="w-full h-full object-cover object-center"
                    src={card}
                  ></img>
                  <div className="px-8 py-4 absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white">
                    <h1 className="text-center text-lg font-bold text-white mb-3">
                      {card.title}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#e7b847"
                        className="w-9 h-9 absolute bottom-2 right-2 hover:scale-110"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {selectedImage && (
          <Modal
            isOpen={true} // Set this to true to open the modal
            onRequestClose={handleCloseImage}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 10000,
              },
              content: {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                borderColor: "transparent",
              },
            }}
          >
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="relative w-fit border border-black">
                <button
                  className="absolute right-5 md:-right-10 -top-14 md:-top-10 text-white bg-palette px-2 hover:scale-105 text-2xl font-bold"
                  onClick={handleCloseImage}
                >
                  X
                </button>
                <img
                  src={selectedImage}
                  alt="rooms"
                  className="object-cover object-center h-96 md:h-152"
                />
              </div>
            </div>
          </Modal>
        )}

        <div className="flex justify-center text-palette mt-5 md:mt-5 mb-5 md:mb-8">
          {currentPage > 1 && (
            <button
              className="text-4xl mr-2 -mt-1 md:mt-0 hover:scale-125"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              &lt; {/* Left arrow */}
            </button>
          )}
          {Array.from({ length: totalPages }, (_, i) => (
            <div
              key={i}
              className={`bg-palette rounded-full py-2 px-4 mx-1 text-white ${
                currentPage === i + 1 ? "bg-palette" : "bg-paletteof"
              }`}
              onClick={() => setCurrentPage(i + 1)}
              style={{ cursor: "pointer" }}
            >
              {i + 1}
            </div>
          ))}
          {currentPage < totalPages && (
            <button
              className="text-4xl ml-2 -mt-1 md:mt-0 hover:scale-125"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              &gt; {/* Right arrow */}
            </button>
          )}
        </div>
        
      </div>
    </div>
  );
}

export default Projects;
