import React, { useState, useEffect, useRef } from "react";
import { Twirl as Hamburger } from "hamburger-react";
import logo from "./../media/logo.svg";
function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getCurrentPath = () => {
    const path = window.location.pathname;
    return path.endsWith("/") ? path.slice(0, -1) : path;
  };
  const currentPath = getCurrentPath();

  const navbarRef = useRef(null);

  // Close the menu when clicked outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isMenuOpen &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  // Make the mobile version navbar sticky
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768) {
        if (window.scrollY > 0) {
          navbarRef.current.classList.add("sticky");
        } else {
          navbarRef.current.classList.remove("sticky");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="sm:ml-4 md:ml-0 sm:mr-0 md:mr-0 ">
      <nav
        ref={navbarRef}
        className={`bg-background md:bg-transparent p-2 md:p-0 ${
          isMenuOpen ? "open-menu" : ""
        }`}
      >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto sm:p-4 md:p-0  ">
          <a href="/" className="md:flex items-center hover:scale-105">
            <img
              src={logo}
              className="h-20 md:h-32 ml-0 "
              alt="M1Floor Logo"
            />
          </a>
        
          <div className="md:hidden sm:block -mt-3 w-9 h-8 mr-4">
            <Hamburger
              label="Show menu"
              easing="ease-in"
              toggled={isMenuOpen}
              toggle={setIsMenuOpen}
              distance="m"
              color="#780a33"
              duration={0.4}
            ></Hamburger>
          </div>
          <div
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
              isMenuOpen ? "block " : "hidden"
            }`}
          >
            <ul className="flex flex-col font-medium  p-4 md:p-0 mt-4 md mb-2 md:mb-0 border border-palette  md:flex-row md:space-x-8 md:mt-0 md:border-0">
              <li>
                <a
                  href="/"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette ${
                    currentPath === ""
                      ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette "
                      : ""
                  }`}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/beschichtungen"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette ${
                    currentPath === "/beschichtungen"
                      ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette "
                      : ""
                  }`}
                >
                  Beschichtungen
                </a>
              </li>
              <li>
                <a
                  href="/kunstharzbelaegen"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette ${
                    currentPath === "/kunstharzbelaegen"
                      ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette "
                      : ""
                  }`}
                >
                  Kunstharzbelaegen
                </a>
              </li>
              <li>
                <a
                  href="/fluessigkunststoff"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette ${
                    currentPath === "/blitzschutze"
                    ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette"
                    : ""
                  }`}
                >
                  Fluessigkunststoff
                </a>
              </li>
              <li>
                <a
                  href="/injektionen"
                  className={`block py-2 pl-3 pr-4  text-palette md:p-0 font-bold hover:border-b-2 border-palette ${
                    currentPath === "/blitzschutze"
                    ? "md:border-b-2 border-palette rounded-sm bg-palette md:bg-transparent text-white md:text-palette"
                    : ""
                  }`}
                >
                  Injektionen
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
