import React from "react";
import "./About.css";
import about from "./../../media/about.jpg";
function About() {
  return (
    <div id="uns" className=" md:mt-10 ">
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className="text-3xl text-palette text-center "
      >
        Über uns
      </h1>

      <div
        data-aos="fade-up"
        data-aos-duration="1600"
        className="sm:grid grid-cols-3"
      >
        <div></div>
        <div className="text-center col-span-1 border-b-2 border-palette mt-4 ml-8 md:ml-24 mr-8 md:mr-24 mb-5 md:mb-10 "></div>
      </div>
      <div className=" md:grid grid-cols-2 ml-8 md:ml-24 mr-8 md:mr-24">
        <div className=" col-span-1">
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="mt-10 md:mt-10"
          >
            <div className=" mx-auto ">
              <h1 className="text-2xl text-palette font-bold mb-3 md:mb-6">
              Wir haben es im Griff
              </h1>
              <p className="text-palette text-base  md:text-lg md:mr-20 mb-4 md:mb-0">
              Qualitätsböden für Innen- und Aussenflächen vom kompetenten Spezialisten. Als Experte für qualitativ hochwertige Bodenbeläge für Innen- und Aussenflächen ist das Unternehmen M1floor GmbH Ihr kompetenter Partner für Neubauten und Instandsetzungsarbeiten.
              Unsere Stärke liegt in der langjährigen Erfahrung und dem tiefgreifenden Fachwissen aus Theorie und Praxis bei speziellen Themen wie fugenlose Industrieböden, Beschichtungen oder Bodeninjektionen. Kompetenzen, die für die hohe Erwartung an die Qualität unserer Arbeit und deren zuverlässige Ausführung unverzichtbar sind.
              Im Rahmen unserer Firmenphilosophie definieren wir Zuverlässigkeit und Pünktlichkeit als unverzichtbare Werte für eine erfolgreiche Partnerschaft. Dies bedeutet, dass vereinbarte Fertigstellungstermine für uns genauso verbindlich sind, wie die Sauberkeit übergebener Baustellen.
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className=" col-span-1"
        >
          <img
            src={about}
            alt="about"
            className="w-full mb-0 md:mb-8 rounded-image "
          />
        </div>
      </div>
    </div>
  );
}
export default About;
