import React from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";

import "./About.css";
function Contact() {
  const libraries = ["places"];
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAwpOK_o31CtNCw1DZB3bMwIbKYRT-_oTw",
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div id="kontakt" className=" mt-10 ">
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className="text-3xl text-palette text-center "
      >
        Kontakt
      </h1>

      <div
        data-aos="fade-up"
        data-aos-duration="1500"
        className="sm:grid grid-cols-3"
      >
        <div></div>
        <div className="text-center col-span-1 border-b-2 border-palette mt-4 ml-8 md:ml-24 mr-8 md:mr-24 mb-5 md:mb-10 "></div>
      </div>
      <div className=" md:grid grid-cols-1 ml-8 md:ml-24 mr-8 md:mr-4">
        <div className=" md:grid grid-cols-3 md:ml-2 ">
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette md:text-xl font-bold">
              {" "}
              Telefonnummer
            </h1>
            <div className="text-palette mt-2 md:mt-4 mb-2 md:mb-0 border-b-2 md:mr-16 border-palette font-semibold ">
              <a href="tel:+41786635342" className="hover:text-paletteof">
              +41 78 663 53 42
              </a>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette md:text-xl font-bold">
              {" "}
              Allgemeine E-Mail
            </h1>
            <div className="text-palette mt-2 md:mt-4 mb-2 md:mb-0 border-b-2 md:mr-16 border-palette font-semibold ">
              <a href="mailTo:info@m1floor.ch" className="hover:text-paletteof">
              info@m1floor.ch
              </a>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" className="">
            <h1 className="text-palette md:text-xl font-bold"> Adress</h1>
            <div className="text-palette font-semibold  mt-2 md:mt-4 mb-2 md:mb-0 border-b border-palette md:mr-16 ">
              <a
                href="https://www.google.ch/maps/place/Mu%C3%A7o+Bodenbel%C3%A4ge+Ademi/@47.428035,8.4750382,64m/data=!3m1!1e3!4m15!1m8!3m7!1s0x47900b688c08dad9:0xd822b7583b504aea!2zQWx0YnVyZ3N0cmFzc2UgMTUsIDgxMDUgUmVnZW5zZG9yZiwg0KjQstCw0ZjRhtCw0YDQuNGY0LA!3b1!8m2!3d47.4279647!4d8.4748381!16s%2Fg%2F11c1xlpy5b!3m5!1s0x47900a3cc44bef5f:0x361e0cf0c895fdbd!8m2!3d47.4279647!4d8.4748381!16s%2Fg%2F11hbpk1mf7?entry=ttu"
                target="_blank"
                className="hover:text-paletteof"
              >
                Altburgstrasse 15,8105 Regensdorf{" "}
              </a>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1200"
          className=" col-span-1 block md:hidden  h-96 mb-20"
        >
           <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d400.2194391032376!2d8.4750382!3d47.428035!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900b688c08dad9%3A0xd822b7583b504aea!2sAltburgstrasse%2015!5e1!3m2!1smk!2smk!4v1706285977428!5m2!1smk!2smk"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className=" my-8 md:grid grid-cols-1 ml-8 md:ml-24 mr-8 md:mr-4" style={{"margin" : "40px"}}>
      <div className=" px-8 md:grid grid-cols-1 md:ml-2 ">
      <div
          data-aos="fade-right"
          data-aos-duration="1200"
          className=" col-span-1 hidden md:block  h-128 mb-20"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d800.4426416602618!2d8.474946844657357!3d47.42778752610874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a3cc44bef5f%3A0x361e0cf0c895fdbd!2sMu%C3%A7o%20Bodenbel%C3%A4ge%20Ademi!5e1!3m2!1sde!2smk!4v1706533797986!5m2!1sde!2smk"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
