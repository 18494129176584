//BLusplenger
import Inj1 from "./../../media/Injektionen/img1.jpg"


const DataBL = [
  {
    category: "Injektionen",
    image: Inj1,
    title: "Injektionen",
  },

];
export default DataBL;
