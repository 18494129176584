import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import DataB from "../../json/Beschichtungen/DataB";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import "./HeaderBa.css";
// import required modules
import { Autoplay, EffectFade } from "swiper/modules";
function HeaderBa() {
  return (
    <div className=" md:mt-10 md:mb-10 ">
      <div className=" md:grid grid-cols-2 ">
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className="block md:hidden col-span-1 "
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={"fade"}
            autoplay={{
              delay: 2500, // Match the progress bar animation duration
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper3 "
          >
            {DataB.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} alt={item.title} />
                <div className="absolute abstract-shape bottom-1 md:bottom-2 left-0 md:left-2">
                  <p className="text-white text-base  font-bold mt-2 ml-4 md:ml-3">
                    {item.title}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className=" col-span-1 ml-8 md:ml-24 mr-8 md:mr-24">
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="mt-10 md:mt-10"
          >
            <div className=" mx-auto ">
              <h1 className="text-3xl md:text-5xl text-palette  font-bold mt-0 md:mt-36 mb-3 md:mb-6">
              Beschichtungen{" "}
              </h1>
              <p className="text-palette md:pr-20 text-base  md:text-xl mt-0 md:mt-10  mb-4 md:mb-0">
              Die Beschichtung von Böden in Industriehallen, Grossküchen oder anderen gewerblichen Objekten ist eine praktische und kostengünstige Alternative zu anderen Bodenbelägen. Sie schützen Betonböden vor mechanischen Beschädigungen und dienen als Sperrriegel vor dem Eintritt von Wasser.
Hervorzuheben sind die Gestaltungsmöglichkeiten im Rahmen der Bodenbeschichtung. Gerne gestalten wir Ihre Böden entsprechend Ihrer individuellen Vorgaben.
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className="col-span-1 hidden md:block ml-8 md:ml-24 mr-8 md:mr-24 md:-mt-10"
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect="fade"
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper"
          >
            {DataB.map((item, index) => {
              console.log("item.image:", item.image); // Log the image URL

              return (
                <SwiperSlide key={index}>
                  <img src={item.image} alt={item.title} />
                  <div className="absolute abstract-shape bottom-1 md:bottom-2 left-0 md:left-2">
                    <p className="text-white text-lg md:text-xl font-bold mt-2  ml-6">
                      {item.title}
                    </p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
export default HeaderBa;
