import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import DataF from "../../json/Fluessigkunststoff/DataF";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import "./HeaderFl.css";
// import required modules
import { Autoplay, EffectFade } from "swiper/modules";
function Header() {
  return (
    <div className=" md:mt-10 md:mb-10 ">
      <div className=" md:grid grid-cols-2 ">
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className="block md:hidden col-span-1"
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={"fade"}
            autoplay={{
              delay: 2500, // Match the progress bar animation duration
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper3"
          >
            {DataF.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} alt={item.title} />
                <div className="absolute abstract-shapeF bottom-1 md:bottom-2 left-0 md:left-2">
                  <p className="text-white text-base  font-bold mt-2 ml-4 md:ml-3">
                    {item.title}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className=" col-span-1 ml-8 md:ml-24 mr-8 md:mr-24">
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="mt-10 md:mt-10"
          >
            <div className=" mx-auto ">
              <h1 className="text-3xl md:text-5xl text-palette  font-bold mt-0 md:mt-36 mb-3 md:mb-6">
              Fluessigkunststoff{" "}
              </h1>
              <p className="text-palette md:pr-20 text-base  md:text-xl mt-0 md:mt-10  mb-4 md:mb-0">
              Dieser spezielle Kunststoff dient zur hochwertigen Versiegelung tragfähiger Rohböden aus Beton, Estrich oder Zement. Bodenabdichtungen aus diesem Material eignen sich für Garagen gleichermassen wie für Kellerräume oder normal strapazierte Gewerbeflächen.
              Das Ergebnis nach dem Auftragen des Flüssigkunststoffs ist ein Bodenbelag mit Eigenschaftenwie stoss-, schlag- und abriebfest, der zusätzliche Sicherheit vor dem Ausrutschen bietet.
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className=" col-span-1 hidden md:block ml-8 md:ml-24 mr-8 md:mr-24 md:-mt-10"
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={"fade"}
            autoplay={{
              delay: 2500, // Match the progress bar animation duration
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper"
          >
            {DataF.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} alt={item.title} />
                <div className="absolute abstract-shapeF bottom-1 md:bottom-2 left-0 md:left-2">
                  <p className="text-white text-base md:text-lg  font-bold mt-2 ml-4 md:ml-3">
                    {item.title}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
export default Header;
